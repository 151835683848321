import React, {useEffect, useState} from 'react';
import { Container, Col, Row } from "react-bootstrap";
import './assets/styles/_index.scss';
import axios from "axios"
import TimeAgo from 'react-timeago'
import frenchStrings from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import parse from 'html-react-parser';
import Stars from "./stars"

const postsPerPage = 12;
let arrayForHoldingPosts = [];

const Reviews = (props) => {
    const [testimonials, setTestimonials] = useState([])
    const formatter = buildFormatter(frenchStrings)
    const [postsToShow, setPostsToShow] = useState([]);
    const [next, setNext] = useState(12);
    useEffect(() => {
        arrayForHoldingPosts = [];
    }, []);


    const getitems = async url => {
        try {
            const { data } = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
                }
            })// could be from env files
            setTestimonials(data.sort((a, b) => new Date(b.createTime) - new Date(a.createTime)))
        } catch (error) {
            // cache it if fail/error;
            console.error(error)
        }
    }
    const loopWithSlice = (start, end) => {
        let slicedtestimonials = testimonials.sort((a, b) => new Date(b.createTime) - new Date(a.createTime))
        const slicedPosts = slicedtestimonials.slice(start, end);
        arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
        setPostsToShow(arrayForHoldingPosts);
    };

    const handleShowMorePosts = () => {
        loopWithSlice(next, next + postsPerPage);
        setNext(next + postsPerPage);
    };
    useEffect(() => {
        testimonials && loopWithSlice(0, postsPerPage);
    }, [testimonials]);


    useEffect(() => {
        let url = `${process.env.GATSBY_STRAPI_SRC.replace('-dev', '')}/stb-google-reviews/google-review-reviews`
        getitems(url);
    }, [])


    return (
        <div className="reviews-wrapper">
            <Container>
                <Row>
                {postsToShow && postsToShow.map((review, index) => {
                    if(review.starRating == "FIVE" && review.comment && review.comment.length > 0) {
                        return(
                            <Col lg={12}>
                                <div className="reviews-wrapper-border">
                                    <Row>
                                        <Col lg={2}>
                                            <div className="d-lg-block d-flex">
                                                <div className="reviews-person">
                                                    <img src={review.reviewer.profilePhotoUrl} className="img-fluid" />
                                                </div>
                                                <div className="reviews-details">
                                                    <div className="reviews-name">{review.reviewer.displayName}</div>
                                                    <div className="reviews-month">
                                                        <TimeAgo date={review.createTime} formatter={formatter} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={10}>
                                            <ul className="list-inline reviews-count">
                                                <Stars count={review.starRating} />
                                            </ul>                                    
                                            {review.comment && review.comment.length > 0 &&
                                            <p className="reviews-desc">
                                                {parse(review.comment)}
                                            </p>
                                            }

                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        )
                    }
                    })}
                    {postsToShow  && postsToShow.length >= next &&
                        <a onClick={handleShowMorePosts} class="btn btn-white-outline btn-banner">Load More</a>
                    }

                </Row>
            </Container>
        </div>
    )
}

export default Reviews
