import React, { useState, useEffect } from "react"
import { Container, Col, Row, InputGroup, FormControl } from "react-bootstrap"
import { Link } from "gatsby"
import Select from "react-select"
import "./assets/styles/_index.scss"
import $ from "jquery"
import { navigate } from "@reach/router"
import SearchInputBox from "../predictive-search/search-areas"
const SearchBanner = () => {
  const options = [
    { value: "buy", label: "Buy" },
    { value: "rent", label: "Rent" },
  ]
  const [propertyval, setPropertyVal] = useState("buy")
  //

  useEffect(() => {
    $('.search-banner-text  a').click(function () {
      $(window).scrollTop(0);
    })
    let url = "/"
    $(".submit-search-banner").click(function () {
      var searcValue = $(".search-banner-wrapper .searchVal").val()
      var searchpage = propertyval == "buy" ? "for-sale" : "to-rent"
      if (searcValue) {
        url = "in-" + searcValue + "/"
      } else {
        url = "in-london/"
      }
      navigate("/property/" + searchpage + "/" + url)
      $(window).scrollTop(0);
    })
  })

  return (
    <section className="search-banner-wrapper">
      <Container>
        <Row className=" d-flex justify-content-center text-center">
          <Col xl={8} className="search-banner-container">
            <div className="title-text-sm">Our Properties</div>
            <h2 className="search-banner-title">
              Explore our collection of <em>prestigious</em> properties
            </h2>
            <div className="search-banner-text">
              Alternatively, browse all of our{" "}
              <Link to={"/property/for-sale/in-london/"}>
                properties for sale
              </Link>{" "}
              or{" "}
              <Link to={"/property/to-rent/in-london/"}>
                properties to rent.
              </Link>
            </div>
            <div className="search-banner-group-search d-flex justify-content-start col-lg-8 mx-auto">
              <InputGroup className="search-banner-input">
                <InputGroup.Text id="inputGroup-sizing-default">
                  <div className="custom-selectbox-form selectbox-dropdown">
                    <Select
                      options={options}
                      isSearchable={false}
                      classNamePrefix={"select-opt"}
                      className="form-control"
                      placeholder="Buy"
                      value={options.find(obj => obj.value === propertyval)}
                      onChange={e => setPropertyVal(e.value)}
                      components={{
                        DropdownIndicator: () => (
                          <i className="icon icon-dropdown-arrow"></i>
                        ),
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                </InputGroup.Text>
                <div className="banner-input d-flex align-items-center">
                  <i className="icon icon-search"></i>
                </div>
                {/* <FormControl
                                    placeholder="Street, area or postcode"
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    className='search-input'
                                /> */}
                <SearchInputBox />
              </InputGroup>
              <button
                href="javascript:;"
                className="submit-search-banner btn btn-fill-primary d-md-flex d-none"
              >
                Search
              </button>
            </div>
            <button
              href="javascript:;"
              className="submit-search-banner btn btn-fill-primary d-md-none d-flex mt-3 w-100"
            >
              Search
            </button>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SearchBanner
